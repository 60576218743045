.menuWrapper {
    border-bottom:none;
}

.menuTop{
    top:0;
    position:fixed;
    width:100%;
    padding:20px;
    z-index: 10;
}
.menuBottom{
    bottom:0;
    position:fixed;
    width:100%;
    padding:20px;
    z-index: 10;
}
.menuSection{
    width: 33.33%;
    float: left;
    position: relative;
    height: 20px;
}
.mobileMenuSectionTitle{
    font-size:20px;
    margin: 20px 0px 50px 20px;
}
.mobileMenuSection{
    font-size:20px;
    margin: 50px 20px;
}
.aboutLink{
width:25%;
float: left;
}
.newsLink{
    width:25%;
    text-align: center;
    float: left;
}
.releasesLink{
    width:25%;
    text-align: center;
    float: left;
}
.videosLink{
    width:25%;
    text-align: right;
    float: left;
}
.liveLink{
    width:50%;
    text-align: center;
    float: left;
}
.contactLink{
    width:50%;
    text-align: center;
    float: left;
}

.menuOuterWrapper{
    width:100%;
    position: fixed;
    height: auto;
    z-index: 999;
}

.menuBtnWrapper{
    position: absolute;
    right: 0;
    top: 0;
    z-index: 9;
}
.menuBtnWrapper img{
    box-shadow: none;
    max-width:20px;
}
.toggleMenuBtn{
    position:absolute;
    right: 20px;
    top: 20px;
    cursor:pointer;
}

.menuBtnRegular{
    position: absolute;
    transition:transform 0.1s ease-in-out;
}

.left {
    transform-origin: left bottom;
    transform: scale(1);
}

.center {
    transform: translatex(-50%) scale(1);
    left: 50%;
    transform-origin: center bottom;
}

.right {
    right:0;
    transform-origin: right bottom;
}

.center:hover{
    transform: translatex(-50%) scale(1.4);
}

.left:hover, .right:hover{
    transform: scale(1.4);
}

.menuBtnExpanded.left, .menuBtnExpanded.right{
    position: absolute;
    transform: scale(1.4);
}

.menuBtnExpanded.center{
    position: absolute;
    transform: translatex(-50%) scale(1.4);
}