/*------------------------------------*\
    RESET
\*------------------------------------*/
/* http://meyerweb.com/eric/tools/css/reset/
    v2.0b1 | 201101
    NOTE:WORK IN PROGRESS
    USE WITH CAUTION AND TEST WITH ABANDON */

    html,body,div,span,applet,object,iframe,
    h1,h2,h3,h4,h5,h6,p,blockquote,pre,
    a,abbr,acronym,address,big,cite,code,
    del,dfn,em,img,ins,kbd,q,s,samp,
    small,strike,strong,sub,sup,tt,var,
    b,u,i,center,
    dl,dt,dd,ol,ul,li,
    fieldset,form,label,legend,
    table,caption,tbody,tfoot,thead,tr,th,td,
    article,aside,canvas,details,figcaption,figure,
    footer,header,hgroup,menu,nav,section,summary,
    time,mark,audio,video{
        margin:0;
        padding:0;
        border:0;
        outline:0;
        font-size:100%;
        font:inherit;
        vertical-align:baseline;
    }
    /* HTML5 display-role reset for older browsers */
    article,aside,details,figcaption,figure,
    footer,header,hgroup,menu,nav,section{
        display:block;
    }
    body{
        line-height:1;
    }
    ol,ul{
        list-style:none;
    }
    blockquote,q{
        quotes:none;
    }
    blockquote:before,blockquote:after,
    q:before,q:after{
        content:’’;
        content:none;
    }
    /* remember to define visible focus styles!
    :focus{
        outline:?????;
    } */
    
    /* remember to highlight inserts somehow! */
    ins{
        text-decoration:none;
    }
    del{
        text-decoration:line-through;
    }
    
    table{
        border-collapse:collapse;
        border-spacing:0;
    }
html {
  box-sizing: border-box;
}
*, *:before, *:after {
  box-sizing: inherit;
}

@font-face {
  font-family: 'pirellimedium';
  src: url('pirelli-medium.woff2') format('woff2'),
       url('pirelli-medium.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}

body{
  font-family:'pirellimedium', sans-serif;
  color:white;
  font-size: 15px;
  line-height: 18px;
  background:gray
}
::-webkit-scrollbar {
  display: none;
  width:0;
}

a {
  color: inherit;
  text-decoration: none;
}

p:empty{
  margin-bottom:10px;
}

p a {
  border-bottom: thin solid white;
}
.underline {
  border-bottom: thin solid white;
}

img{box-shadow: 0px 0px 7px rgba(0,0,0,0.3);}

.page-wrapper{
  position: relative;
  height: 100vh;
}

.animate-div{
  position: relative;
  width: 100vw;
  height: 100vh;
}

.close-wrapper{
  position: relative;
  float: left;
  padding-bottom: 20px;
}
.close-wrapper svg{
  float: right;
}

.frontpage-image-wrapper{
  position: absolute;
  width: calc(100vh - 300px);
  height: calc(100vh - 300px);
  max-width: calc(100vw - 40px);
  left:50%;
  top:50%;
  transform: translate(-50%, -50%);
  z-index: 999;
}

.frontpage-image{
  float: left;
  height: 100%;
  width: 100%;
  text-align: center;
  display:grid;
  align-content: center;
  grid-template-rows: auto auto auto;
}
.frontpage-caption{
  float: left;
  padding: 20px 0px;
  text-align: center;
  width: 100%;
}
/* .frontpage-image a{
  display: grid;
    align-content: center;
    height: 100%;
} */
.frontpage-image img{
  max-width: 100%;
  max-height: 100%;
  text-align: center;
}

#aboutSectionContent{
  position: fixed;
  width: 260px;
  height: calc(100vh - 180px);
  top: 90px;
  overflow: scroll;
  left: 20px;
}

#contactSectionContent{
  position: absolute;
  right:20px;
  bottom: 90px;
  text-align: right;
  overflow: scroll;
  width: 25%;
}
.contactSocialIcons{
  height: 20px;
  margin-top: 25px;
}
.contactSocialIcon{
  height: 100%;
  float: right;
  margin-left: 40px;
}

.contactSocialIcons img{
  max-height:100%;
  box-shadow: none;
}

#releasesSectionContent{
  position: absolute;
  top:40px;
  width: 80%;
  left: 50%;
  transform: translatex(-50%);
  height:100%;
  padding:10px;
  display:flex;
  flex-wrap: wrap;
}

.singleRelease{
  flex-basis:25%;
  width:25%;
  padding:20px;
}

.singleReleaseImage{
  max-width:200px;
}
.singleReleaseText{
  padding:10px 0px;
}

.spotifyLink{
max-height: 15px;
box-shadow: none;
margin-right:10px;
}
.appleMusicLink{
  max-height: 15px;
  box-shadow: none;
  margin-right:10px;
}

.padding{
  width: 100%;
  height: 150px;
}

#sheetsSectionContent{
  position: absolute;
  top:90px;
  width: 70%;
  left: 50%;
  transform: translatex(-50%);
  height:calc(100vh - 180px);
  overflow: scroll;
  padding:10px;
  display:flex;
  flex-wrap: wrap;
}

.singleSheet{
  flex-basis:25%;
  width:25%;
  padding:10px;
}

.singleSheetInfo{
  padding-top: 10px;
}

#liveSectionContent{
  width: 100%;
  position: fixed;
  top: 0px;
  padding: 90px 20px;
  overflow-y: scroll;
  height: 100%;
}

.singleLiveSectionEntry{
  width:100%;
  padding-bottom: 30px;
}
.singleLiveSectionDate{
  width:12%;
  float:left;
}
.singleLiveSectionVenue{
  width:50%;
  float:left;
}
.singleLiveSectionMusician{
  width:20%;
  float:left;
}
.singleLiveSectionTicketUrl{
  width:18%;
  float:left;
  text-align: right;
}

.dateItemDay{
  float: left;
  width: 25%;
}
.dateItemMonth{
  float: left;
  width: 25%;
}
.dateItemYear{
  float: left;
  width: 50%;
}

#videosSectionContent{
  position: absolute;
  top: 60px;
  width: 80%;
  left: 50%;
  transform: translatex(-50%);
  height: calc(100vh - 180px);
  overflow: scroll;
  padding: 10px;
  display:flex;
  flex-wrap: wrap;
}
.videosGridElement{
  flex-basis:25%;
}

.videoEntrySmall, .videoEntryExpanded{
  float:none;
  width:auto;
  height: 100%;
  margin-bottom:20px;
  position:relative;
}
.videoEntryExpanded{
  margin-bottom:10px
}
.videoEntryWrapper{
  margin-bottom: 10px;
}

.expandedVideoDisplayInnerWrapper{
  position:relative;
}

.expandedVideoDisplayWrapper{
  opacity: 1;
    position: fixed;
    z-index: 9;
    width: 100%;
    height: calc(100vh - 220px);
    display: flex;
    align-items: center;
    justify-content: center;
    top: 50%;
    transform: translateY(-50%);
}

.expandedVideoDisplayInnerWrapperInfo{
  float:left;
  padding-top: 10px;
}
.expandedVideoCloseBtn{
  cursor:pointer;
  position:absolute;
  right:0;
}

.cross{
  box-shadow: none;
  max-width: 20px;
  padding-top: 8px;
}

.videoEntryYoutubeEmbed{
  height:0;
  position:relative;
  padding-bottom: 56.25%;
  width: 700px;
}
.videoEntryYoutubeEmbed iframe{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.videoEntryThumbnail{
    cursor: pointer;
    padding-bottom: 53px;
    background-position: center;
    background-size: 97px;
    width: 88px;
    box-shadow: 0px 0px 7px rgba(0,0,0,0.3);
}

/* .videoEntryThumbnail:hover{
  transform:scale(1.05);
} */

.videoEntryThumbnail img {
  max-width: 100%;
  height: 100%;
}
.videoInfo{
  margin-top: 20px;
}


@media screen and (max-width:1000px) {

  body{
    font-size: 16px;
    line-height: 20px;
  }

  #aboutSectionContent,
  #contactSectionContent,
  #releasesSectionContent,
  #sheetsSectionContent,
  #liveSectionContent,
  #videosSectionContent{
    position: fixed;
    width: calc(100% - 40px);
    height: calc(100% - 70px);
    top: 70px;
    overflow: scroll;
    left: 20px;
    padding: 0;
    transform: none;
    display: block;
    text-align: left;
    padding-top: 40px;
    flex-wrap: wrap;
  }

  #aboutSectionContent{
    padding-right:20px
  }

  /* LIVE */

.singleLiveSectionEntry {
  width: 100%;
  padding-bottom: 30px;
  float: left;
  position: relative;
}
.singleLiveSectionDate {
  width: 100%;
  float: left;
}
.dateItemDay {
  float: left;
  width: 20px;
}
.dateItemMonth {
  float: left;
  width: 20px;
}
.dateItemYear {
  float: left;
  width: auto;
}
.singleLiveSectionVenue {
  width: 100%;
  float: left;
}

.singleLiveSectionMusician {
  width: 100%;
  float: left;
}
.singleLiveSectionTicketUrl{
  float: left;
  text-align: left;
}

/* RELEASES */

.singleRelease {
  width: 100%;
  padding: 20px 0px;
}

.singleReleaseImage {
  max-width: 50%;
}

/* VIDEOS */

.videosGridElement {
  margin-bottom: 50px;
}
.videoEntryYoutubeEmbed {
  width: calc(100vw - 40px);
}

/* CONTACT */

.contactSocialIcon {
  float: left;
  margin-right: 40px;
  margin-left: 0;
}

/* SHEETS */

.singleSheet {
  flex-basis: auto;
  width: auto;
  padding: 0px;
  margin-bottom: 30px;
}

}


@media screen and (max-width:700px) {

  .frontpage-image-wrapper{
    width: calc(100vw - 50px) !important;
    height: calc(100vw - 50px) !important;
  }

}